import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact"
import Layout from "../components/hoc/Layout"
const DaraPrivacy = () => {
  return (
    <Layout >
      <div className="impressum">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <MDBTypography tag="h1">Datenschutz­erklärung</MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div className="imp-text">
        <MDBContainer>
          <MDBRow>
            <div>
              
              <h2>1. Datenschutz auf einen Blick</h2>
              <h3>Allgemeine Hinweise</h3>{" "}
              <p>
                Die folgenden Hinweise geben einen einfachen Überblick darüber,
                was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
                Website besuchen. Personenbezogene Daten sind alle Daten, mit
                denen Sie persönlich identifiziert werden können. Ausführliche
                Informationen zum Thema Datenschutz entnehmen Sie unserer unter
                diesem Text aufgeführten Datenschutzerklärung.
              </p>
              <h3>Datenerfassung auf dieser Website</h3>{" "}
              <h4>
                Wer ist verantwortlich für die Datenerfassung auf dieser
                Website?
              </h4>{" "}
              <p>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
                „Hinweis zur Verantwortlichen Stelle“ in dieser
                Datenschutzerklärung entnehmen.
              </p>{" "}
              <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
              <p>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
                in ein Kontaktformular eingeben.
              </p>{" "}
              <p>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung
                beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                sind vor allem technische Daten (z. B. Internetbrowser,
                Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
                dieser Daten erfolgt automatisch, sobald Sie diese Website
                betreten.
              </p>{" "}
              <h4>Wofür nutzen wir Ihre Daten?</h4>{" "}
              <p>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie
                Bereitstellung der Website zu gewährleisten. Andere Daten können
                zur Analyse Ihres Nutzerverhaltens verwendet werden.
              </p>{" "}
              <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>{" "}
              <p>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                Herkunft, Empfänger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
                Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                können Sie diese Einwilligung jederzeit für die Zukunft
                widerrufen. Außerdem haben Sie das Recht, unter bestimmten
                Umständen die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen
                ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              </p>{" "}
              <p>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                sich jederzeit an uns wenden.
              </p>
              <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>{" "}
              <p>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
                ausgewertet werden. Das geschieht vor allem mit sogenannten
                Analyseprogrammen.
              </p>{" "}
              <p>
                Detaillierte Informationen zu diesen Analyseprogrammen finden
                Sie in der folgenden Datenschutzerklärung.
              </p>
              <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
              <h3>Externes Hosting</h3>{" "}
              <p>
                Diese Website wird bei einem externen Dienstleister gehostet
                (Hoster). Die personenbezogenen Daten, die auf dieser Website
                erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
                Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                Namen, Websitezugriffe und sonstige Daten, die über eine Website
                generiert werden, handeln.
              </p>{" "}
              <p>
                Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
                gegenüber unseren potenziellen und bestehenden Kunden (Art. 6
                Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
                und effizienten Bereitstellung unseres Online-Angebots durch
                einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
              </p>{" "}
              <p>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                zur Erfüllung seiner Leistungspflichten erforderlich ist und
                unsere Weisungen in Bezug auf diese Daten befolgen.
              </p>{" "}
              <p>Wir setzen folgenden Hoster ein:</p>
              <p>
                Netlify, Inc., 2325 3rd Street, Suite 215, San Francisco, 94107
                CA
              </p>
              <h3>Cloudflare</h3>{" "}
              <p>
                Wir nutzen den Service „Cloudflare“. Anbieter ist die Cloudflare
                Inc., 101 Townsend St., San Francisco, CA 94107, USA (im
                Folgenden „Cloudflare”).
              </p>{" "}
              <p>
                Cloudflare bietet ein weltweit verteiltes Content Delivery
                Network mit DNS an. Dabei wird technisch der
                Informationstransfer zwischen Ihrem Browser und unserer Website
                über das Netzwerk von Cloudflare geleitet. Das versetzt
                Cloudflare in die Lage, den Datenverkehr zwischen Ihrem Browser
                und unserer Website zu analysieren und als Filter zwischen
                unseren Servern und potenziell bösartigem Datenverkehr aus dem
                Internet zu dienen. Hierbei kann Cloudflare auch Cookies oder
                sonstige Technologien zur Wiedererkennung von Internetnutzern
                einsetzen, die jedoch allein zum hier beschriebenen Zweck
                verwendet werden.
              </p>{" "}
              <p>
                Der Einsatz von Cloudflare beruht auf unserem berechtigten
                Interesse an einer möglichst fehlerfreien und sicheren
                Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f
                DSGVO).
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://www.cloudflare.com/privacypolicy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.cloudflare.com/privacypolicy/
                </a>
                .
              </p>{" "}
              <p>
                Weitere Informationen zum Thema Sicherheit und Datenschutz bei
                Cloudflare finden Sie hier:{" "}
                <a
                  href="https://www.cloudflare.com/privacypolicy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.cloudflare.com/privacypolicy/
                </a>
                .
              </p>
              <h3>Google Cloud CDN</h3>{" "}
              <p>
                Wir nutzen das Content Delivery Network Google Cloud CDN.
                Anbieter ist die Google Ireland Limited („Google“), Gordon
                House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Google bietet ein weltweit verteiltes Content Delivery Network
                an. Dabei wird technisch der Informationstransfer zwischen Ihrem
                Browser und unserer Website über das Netzwerk von Google
                geleitet. Hierdurch können wir die weltweite Erreichbarkeit und
                die Leistungsfähigkeit unserer Website erhöhen.
              </p>{" "}
              <p>
                Der Einsatz von Google Cloud CDN beruht auf unserem berechtigten
                Interesse an einer möglichst fehlerfreien und sicheren
                Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f
                DSGVO).
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://cloud.google.com/terms/eu-model-contract-clause"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/terms/eu-model-contract-clause
                </a>
                .
              </p>{" "}
              <p>
                Weitere Informationen zu Google Cloud CDN finden Sie hier:{" "}
                <a
                  href="https://cloud.google.com/cdn/docs/overview?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/cdn/docs/overview?hl=de
                </a>
                .
              </p>
              <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
              <h3>Datenschutz</h3>{" "}
              <p>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                vertraulich und entsprechend der gesetzlichen
                Datenschutzvorschriften sowie dieser Datenschutzerklärung.
              </p>{" "}
              <p>
                Wenn Sie diese Website benutzen, werden verschiedene
                personenbezogene Daten erhoben. Personenbezogene Daten sind
                Daten, mit denen Sie persönlich identifiziert werden können. Die
                vorliegende Datenschutzerklärung erläutert, welche Daten wir
                erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
                welchem Zweck das geschieht.
              </p>{" "}
              <p>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
                B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                Dritte ist nicht möglich.
              </p>
              <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
              <p>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                Website ist:
              </p>{" "}
              <p>
                Qimia GmbH
                <br />
                Mehdi Pourfallahi, Saba Fallah
                <br />
                Brüsseler Str. 89-93
                <br />
                50672 Köln
              </p>
              <br />
              <p>
                Telefon: +49 22157967940
                <br />
                E-Mail: contact@qimia.de
              </p>
              <p>
                Verantwortliche Stelle ist die natürliche oder juristische
                Person, die allein oder gemeinsam mit anderen über die Zwecke
                und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
                Namen, E-Mail-Adressen o. Ä.) entscheidet.
              </p>
              <h3>Speicherdauer</h3>{" "}
              <p>
                Soweit innerhalb dieser Datenschutzerklärung keine speziellere
                Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
                Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
                Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
                Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
                gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe
                für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
                steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
                Gründe.
              </p>
              <h3>Gesetzlich vorgeschriebener Datenschutz­beauftragter</h3>{" "}
              <p>
                Wir haben für unser Unternehmen einen Datenschutzbeauftragten
                bestellt.
              </p>
              <p>
                Dr. Mahshin Pourfallahi
                <br />
                Qimia GmbH
                <br />
                Brüsseler Str. 89-93
                <br />
                50672 Köln
              </p>
              <br />
              <p>
                Telefon: +49 22157967940
                <br />
                E-Mail: ma.pourfallahi@qimia.de
              </p>
              <h3>
                Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
              </h3>{" "}
              <p>
                Auf unserer Website sind unter anderem Tools von Unternehmen mit
                Sitz in den USA oder sonstigen datenschutzrechtlich nicht
                sicheren Drittstaaten eingebunden. Wenn diese Tools aktiv sind,
                können Ihre personenbezogene Daten in diese Drittstaaten
                übertragen und dort verarbeitet werden. Wir weisen darauf hin,
                dass in diesen Ländern kein mit der EU vergleichbares
                Datenschutzniveau garantiert werden kann. Beispielsweise sind
                US-Unternehmen dazu verpflichtet, personenbezogene Daten an
                Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
                hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
                ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste)
                Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken
                verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
                diese Verarbeitungstätigkeiten keinen Einfluss.
              </p>
              <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
              <p>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                ausdrücklichen Einwilligung möglich. Sie können eine bereits
                erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit
                der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                Widerruf unberührt.
              </p>
              <h3>
                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                sowie gegen Direktwerbung (Art. 21 DSGVO)
              </h3>{" "}
              <p>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN
                DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
                GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN
                EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
                IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN,
                ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
              </p>{" "}
              <p>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
                SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                NACH ART. 21 ABS. 2 DSGVO).
              </p>
              <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>{" "}
              <p>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
                Beschwerderecht besteht unbeschadet anderweitiger
                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
              </p>
              <h3>Recht auf Daten­übertrag­barkeit</h3>{" "}
              <p>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erfüllung eines Vertrags automatisiert
                verarbeiten, an sich oder an einen Dritten in einem gängigen,
                maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
                direkte Übertragung der Daten an einen anderen Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
              </p>
              <h3>SSL- bzw. TLS-Verschlüsselung</h3>{" "}
              <p>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                erkennen Sie daran, dass die Adresszeile des Browsers von
                http://“ auf https://“ wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
              </p>{" "}
              <p>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden.
              </p>
              <h3>Auskunft, Löschung und Berichtigung</h3>{" "}
              <p>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                gespeicherten personenbezogenen Daten, deren Herkunft und
                Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
                auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
                weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                jederzeit an uns wenden.
              </p>
              <h3>Recht auf Einschränkung der Verarbeitung</h3>{" "}
              <p>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Hierzu können Sie sich
                jederzeit an uns wenden. Das Recht auf Einschränkung der
                Verarbeitung besteht in folgenden Fällen:
              </p>{" "}
              <ul>
                {" "}
                <li>
                  Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                  personenbezogenen Daten bestreiten, benötigen wir in der Regel
                  Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben
                  Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </li>{" "}
                <li>
                  Wenn die Verarbeitung Ihrer personenbezogenen Daten
                  unrechtmäßig geschah/geschieht, können Sie statt der Löschung
                  die Einschränkung der Datenverarbeitung verlangen.
                </li>{" "}
                <li>
                  Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
                  Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung
                  von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                  Löschung die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </li>{" "}
                <li>
                  Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                  haben, muss eine Abwägung zwischen Ihren und unseren
                  Interessen vorgenommen werden. Solange noch nicht feststeht,
                  wessen Interessen überwiegen, haben Sie das Recht, die
                  Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                  zu verlangen.
                </li>{" "}
              </ul>{" "}
              <p>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
                abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                der Rechte einer anderen natürlichen oder juristischen Person
                oder aus Gründen eines wichtigen öffentlichen Interesses der
                Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
              </p>
              <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
              <p>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit
                widersprochen. Die Betreiber der Seiten behalten sich
                ausdrücklich rechtliche Schritte im Falle der unverlangten
                Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
              </p>
              <br />
              <h2>4. Datenerfassung auf dieser Website</h2>
              <h3>Cookies</h3>{" "}
              <p>
                Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
                sind kleine Textdateien und richten auf Ihrem Endgerät keinen
                Schaden an. Sie werden entweder vorübergehend für die Dauer
                einer Sitzung (Session-Cookies) oder dauerhaft (permanente
                Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden
                nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies
                bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst
                löschen oder eine automatische Löschung durch Ihren Webbrowser
                erfolgt.
              </p>{" "}
              <p>
                Teilweise können auch Cookies von Drittunternehmen auf Ihrem
                Endgerät gespeichert werden, wenn Sie unsere Seite betreten
                (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
                Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
                Cookies zur Abwicklung von Zahlungsdienstleistungen).
              </p>{" "}
              <p>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                technisch notwendig, da bestimmte Websitefunktionen ohne diese
                nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
                Anzeige von Videos). Andere Cookies dienen dazu, das
                Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
              </p>{" "}
              <p>
                Cookies, die zur Durchführung des elektronischen
                Kommunikationsvorgangs (notwendige Cookies) oder zur
                Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
                (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
                Optimierung der Website (z.B. Cookies zur Messung des
                Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                berechtigtes Interesse an der Speicherung von Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung seiner
                Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
                abgefragt wurde, erfolgt die Speicherung der betreffenden
                Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6
                Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit
                widerrufbar.
              </p>{" "}
              <p>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                von Cookies informiert werden und Cookies nur im Einzelfall
                erlauben, die Annahme von Cookies für bestimmte Fälle oder
                generell ausschließen sowie das automatische Löschen der Cookies
                beim Schließen des Browsers aktivieren. Bei der Deaktivierung
                von Cookies kann die Funktionalität dieser Website eingeschränkt
                sein.
              </p>{" "}
              <p>
                Soweit Cookies von Drittunternehmen oder zu Analysezwecken
                eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
                Datenschutzerklärung gesondert informieren und ggf. eine
                Einwilligung abfragen.
              </p>
              <h3>Cookie-Einwilligung mit Usercentrics</h3>{" "}
              <p>
                Diese Website nutzt die Cookie-Consent-Technologie von
                Usercentrics, um Ihre Einwilligung zur Speicherung bestimmter
                Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter
                Technologien einzuholen und diese datenschutzkonform zu
                dokumentieren. Anbieter dieser Technologie ist die Usercentrics
                GmbH, Rosental 4, 80331 München, Website:{" "}
                <a
                  href="https://usercentrics.com/de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://usercentrics.com/de/
                </a>{" "}
                (im Folgenden „Usercentrics“).
              </p>{" "}
              <p>
                Wenn Sie unsere Website betreten, werden folgende
                personenbezogene Daten an Usercentrics übertragen:
              </p>{" "}
              <ul>
                {" "}
                <li>
                  Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)
                </li>{" "}
                <li>Ihre IP-Adresse</li>{" "}
                <li>Informationen über Ihren Browser</li>{" "}
                <li>Informationen über Ihr Endgerät</li>{" "}
                <li>Zeitpunkt Ihres Besuchs auf der Website</li>{" "}
              </ul>{" "}
              <p>
                Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser,
                um Ihnen die erteilten Einwilligungen bzw. deren Widerruf
                zuordnen zu können. Die so erfassten Daten werden gespeichert,
                bis Sie uns zur Löschung auffordern, das Usercentrics-Cookie
                selbst löschen oder der Zweck für die Datenspeicherung entfällt.
                Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.
              </p>{" "}
              <p>
                Der Einsatz von Usercentrics erfolgt, um die gesetzlich
                vorgeschriebenen Einwilligungen für den Einsatz bestimmter
                Technologien einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs.
                1 lit. c DSGVO.
              </p>
              <h3>Cookie-Einwilligung mit Borlabs Cookie</h3>{" "}
              <p>
                Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs
                Cookie, um Ihre Einwilligung zur Speicherung bestimmter Cookies
                in Ihrem Browser einzuholen und diese datenschutzkonform zu
                dokumentieren. Anbieter dieser Technologie ist Borlabs -
                Benjamin A. Bornschein, Rübenkamp 32, 22305 Hamburg (im
                Folgenden Borlabs).
              </p>{" "}
              <p>
                Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in
                Ihrem Browser gespeichert, in dem die von Ihnen erteilten
                Einwilligungen oder der Widerruf dieser Einwilligungen
                gespeichert werden. Diese Daten werden nicht an den Anbieter von
                Borlabs Cookie weitergegeben.
              </p>{" "}
              <p>
                Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung
                auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck
                für die Datenspeicherung entfällt. Zwingende gesetzliche
                Aufbewahrungsfristen bleiben unberührt. Details zur
                Datenverarbeitung von Borlabs Cookie finden Sie unter{" "}
                <a
                  href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
                </a>
                .
              </p>{" "}
              <p>
                Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um
                die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz
                von Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs.
                1 lit. c DSGVO.
              </p>
              <h3>Cookie-Einwilligung mit Cookiebot</h3>{" "}
              <p>
                Unsere Website nutzt die Cookie-Consent-Technologie von
                Cookiebot, um Ihre Einwilligung zur Speicherung bestimmter
                Cookies auf Ihrem Endgerät einzuholen und diese
                datenschutzkonform zu dokumentieren. Anbieter dieser Technologie
                ist Cybot A/S, Havnegade 39, 1058 Kopenhagen, Dänemark (im
                Folgenden „Cookiebot“).
              </p>{" "}
              <p>
                Wenn Sie unsere Website betreten, wird eine Verbindung zu den
                Servern von Cookiebot hergestellt, um Ihre Einwilligungen und
                sonstigen Erklärungen zur Cookie-Nutzung einzuholen.
                Anschließend speichert Cookiebot einen Cookie in Ihrem Browser,
                um Ihnen die erteilten Einwilligungen bzw. deren Widerruf
                zuordnen zu können. Die so erfassten Daten werden gespeichert,
                bis Sie uns zur Löschung auffordern, den Cookiebot-Cookie selbst
                löschen oder der Zweck für die Datenspeicherung entfällt.
                Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.
              </p>{" "}
              <p>
                Der Einsatz von Cookiebot erfolgt, um die gesetzlich
                vorgeschriebenen Einwilligungen für den Einsatz von Cookies
                einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c
                DSGVO.
              </p>
              <h3>Cookie-Einwilligung mit Consent Manager Provider</h3>{" "}
              <p>
                Unsere Website nutzt die Cookie-Consent-Technologie von Consent
                Manager Provider, um Ihre Einwilligung zur Speicherung
                bestimmter Cookies auf Ihrem Endgerät einzuholen und diese
                datenschutzkonform zu dokumentieren. Anbieter dieser Technologie
                ist die Jaohawi AB, Håltegelvägen 1b, 72348 Västerås, Schweden,
                Website:{" "}
                <a
                  href="https://www.consentmanager.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.consentmanager.de
                </a>{" "}
                (im Folgenden „Consent Manager Provider“).
              </p>{" "}
              <p>
                Wenn Sie unsere Website betreten, wird eine Verbindung zu den
                Servern von Consent Manager Provider hergestellt, um Ihre
                Einwilligungen und sonstigen Erklärungen zur Cookie-Nutzung
                einzuholen. Anschließend speichert Consent Manager Provider ein
                Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen
                bzw. deren Widerruf zuordnen zu können. Die so erfassten Daten
                werden gespeichert, bis Sie uns zur Löschung auffordern, den
                Consent-Manager-Provider-Cookie selbst löschen oder der Zweck
                für die Datenspeicherung entfällt. Zwingende gesetzliche
                Aufbewahrungspflichten bleiben unberührt.
              </p>{" "}
              <p>
                Der Einsatz von Consent Manager Provider erfolgt, um die
                gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von
                Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1
                lit. c DSGVO.
              </p>
              <h3>Server-Log-Dateien</h3>{" "}
              <p>
                Der Provider der Seiten erhebt und speichert automatisch
                Informationen in so genannten Server-Log-Dateien, die Ihr
                Browser automatisch an uns übermittelt. Dies sind:
              </p>{" "}
              <ul>
                {" "}
                <li>Browsertyp und Browserversion</li>{" "}
                <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
                <li>Hostname des zugreifenden Rechners</li>{" "}
                <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
              </ul>{" "}
              <p>
                Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                nicht vorgenommen.
              </p>{" "}
              <p>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der technisch fehlerfreien Darstellung und der
                Optimierung seiner Website – hierzu müssen die Server-Log-Files
                erfasst werden.
              </p>
              <h3>Kontaktformular</h3>{" "}
              <p>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                werden Ihre Angaben aus dem Anfrageformular inklusive der von
                Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                Anfrage und für den Fall von Anschlussfragen bei uns
                gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
                weiter.
              </p>{" "}
              <p>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der
                effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6
                Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO) sofern diese abgefragt wurde.
              </p>{" "}
              <p>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben
                bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
                zur Speicherung widerrufen oder der Zweck für die
                Datenspeicherung entfällt (z. B. nach abgeschlossener
                Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
                insbesondere Aufbewahrungsfristen – bleiben unberührt.
              </p>
              <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
              <p>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                Ihre Anfrage inklusive aller daraus hervorgehenden
                personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </p>{" "}
              <p>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der
                effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6
                Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO) sofern diese abgefragt wurde.
              </p>{" "}
              <p>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                Einwilligung zur Speicherung widerrufen oder der Zweck für die
                Datenspeicherung entfällt (z. B. nach abgeschlossener
                Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
                – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
                unberührt.
              </p>
              <h3>Hubspot CRM</h3>{" "}
              <p>
                Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot
                Inc. 25 Street, Cambridge, MA 02141 USA (nachfolgend Hubspot
                CRM).
              </p>{" "}
              <p>
                Hubspot CRM ermöglicht es uns unter anderem, bestehende und
                potenzielle Kunden sowie Kundenkontakte zu verwalten. Mit Hilfe
                von Hubspot CRM sind wir in der Lage, Kundeninteraktionen per
                E-Mail, Social Media oder Telefon über verschiedene Kanäle
                hinweg zu erfassen, zu sortieren und zu analysieren. Die so
                erfassten personenbezogenen Daten können ausgewertet und für die
                Kommunikation mit dem potenziellen Kunden oder für
                Marketingmaßnahmen (z.B. Newslettermailings) verwendet werden.
                Mit Hubspot CRM sind wir ferner in der Lage, das Nutzerverhalten
                unserer Kontakte auf unserer Website zu erfassen und zu
                analysieren.
              </p>{" "}
              <p>
                Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an einer möglichst effizienten Kundenverwaltung und
                Kundenkommunikation. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                jederzeit widerrufbar.
              </p>{" "}
              <p>
                Details entnehmen Sie der Datenschutzerklärung von Hubspot:{" "}
                <a
                  href="https://legal.hubspot.com/de/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://legal.hubspot.com/de/privacy-policy
                </a>
                .
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://www.hubspot.de/data-privacy/privacy-shield"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.hubspot.de/data-privacy/privacy-shield
                </a>
                .
              </p>
              <br />
              <h2>5. Analyse-Tools und Werbung</h2>
              <h3>Google Tag Manager</h3>{" "}
              <p>
                Wir setzen den Google Tag Manager ein. Anbieter ist die Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir
                Tracking- oder Statistik-Tools und andere Technologien auf
                unserer Website einbinden können. Der Google Tag Manager selbst
                erstellt keine Nutzerprofile, speichert keine Cookies und nimmt
                keine eigenständigen Analysen vor. Er dient lediglich der
                Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der
                Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an
                das Mutterunternehmen von Google in die Vereinigten Staaten
                übertragen werden kann.
              </p>{" "}
              <p>
                Der Einsatz des Google Tag Managers erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer schnellen und unkomplizierten
                Einbindung und Verwaltung verschiedener Tools auf seiner
                Website. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
                Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>
              <h3>Google Analytics</h3>{" "}
              <p>
                Diese Website nutzt Funktionen des Webanalysedienstes Google
                Analytics. Anbieter ist die Google Ireland Limited („Google“),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Google Analytics ermöglicht es dem Websitebetreiber, das
                Verhalten der Websitebesucher zu analysieren. Hierbei erhält der
                Websitebetreiber verschiedene Nutzungsdaten, wie z.B.
                Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
                Herkunft des Nutzers. Diese Daten werden von Google ggf. in
                einem Profil zusammengefasst, das dem jeweiligen Nutzer bzw.
                dessen Endgerät zugeordnet ist.
              </p>{" "}
              <p>
                Google Analytics verwendet Technologien, die die Wiedererkennung
                des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
                ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die von
                Google erfassten Informationen über die Benutzung dieser Website
                werden in der Regel an einen Server von Google in den USA
                übertragen und dort gespeichert.
              </p>{" "}
              <p>
                Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art.
                6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
                Webangebot als auch seine Werbung zu optimieren. Sofern eine
                entsprechende Einwilligung abgefragt wurde (z. B. eine
                Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
                a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://privacy.google.com/businesses/controllerterms/mccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/controllerterms/mccs/
                </a>
                .
              </p>{" "}
              <h4>IP Anonymisierung</h4>{" "}
              <p>
                Wir haben auf dieser Website die Funktion IP-Anonymisierung
                aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
                Mitgliedstaaten der Europäischen Union oder in anderen
                Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
                Ausnahmefällen wird die volle IP-Adresse an einen Server von
                Google in den USA übertragen und dort gekürzt. Im Auftrag des
                Betreibers dieser Website wird Google diese Informationen
                benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                über die Websiteaktivitäten zusammenzustellen und um weitere mit
                der Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
                Die im Rahmen von Google Analytics von Ihrem Browser
                übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt.
              </p>
              <h4>Browser Plugin</h4>{" "}
              <p>
                Sie können die Erfassung und Verarbeitung Ihrer Daten durch
                Google verhindern, indem Sie das unter dem folgenden Link
                verfügbare Browser-Plugin herunterladen und installieren:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                .
              </p>{" "}
              <p>
                Mehr Informationen zum Umgang mit Nutzerdaten bei Google
                Analytics finden Sie in der Datenschutzerklärung von Google:{" "}
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245?hl=de
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
                abgeschlossen und setzen die strengen Vorgaben der deutschen
                Datenschutzbehörden bei der Nutzung von Google Analytics
                vollständig um.
              </p>
              <h4>Demografische Merkmale bei Google Analytics</h4>{" "}
              <p>
                Diese Website nutzt die Funktion „demografische Merkmale“ von
                Google Analytics, um den Websitebesuchern passende Werbeanzeigen
                innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch
                können Berichte erstellt werden, die Aussagen zu Alter,
                Geschlecht und Interessen der Seitenbesucher enthalten. Diese
                Daten stammen aus interessenbezogener Werbung von Google sowie
                aus Besucherdaten von Drittanbietern. Diese Daten können keiner
                bestimmten Person zugeordnet werden. Sie können diese Funktion
                jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto
                deaktivieren oder die Erfassung Ihrer Daten durch Google
                Analytics wie im Punkt „Widerspruch gegen Datenerfassung“
                dargestellt generell untersagen.
              </p>
              <h4>Speicherdauer</h4>{" "}
              <p>
                Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
                mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z.
                B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden
                nach 26 Monaten anonymisiert bzw. gelöscht. Details hierzu
                ersehen Sie unter folgendem Link:{" "}
                <a
                  href="https://support.google.com/analytics/answer/7667196?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/7667196?hl=de
                </a>
              </p>
              <h3>Google Conversion-Tracking</h3>{" "}
              <p>
                Diese Website nutzt Google Conversion Tracking. Anbieter ist die
                Google Ireland Limited („Google“), Gordon House, Barrow Street,
                Dublin 4, Irland.
              </p>{" "}
              <p>
                Mit Hilfe von Google-Conversion-Tracking können Google und wir
                erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
                können wir beispielsweise auswerten, welche Buttons auf unserer
                Website wie häufig geklickt und welche Produkte besonders häufig
                angesehen oder gekauft wurden. Diese Informationen dienen dazu,
                Conversion-Statistiken zu erstellen. Wir erfahren die
                Gesamtanzahl der Nutzer, die auf unsere Anzeigen geklickt haben
                und welche Aktionen sie durchgeführt haben. Wir erhalten keine
                Informationen, mit denen wir den Nutzer persönlich
                identifizieren können. Google selbst nutzt zur Identifikation
                Cookies oder vergleichbare Wiedererkennungstechnologien.
              </p>{" "}
              <p>
                Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage
                von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
                sowohl sein Webangebot als auch seine Werbung zu optimieren.
                Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
                eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
                a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Mehr Informationen zu Google Conversion-Tracking finden Sie in
                den Datenschutzbestimmungen von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Facebook Pixel</h3>{" "}
              <p>
                Diese Website nutzt zur Konversionsmessung der
                Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist
                die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
                Irland. Die erfassten Daten werden nach Aussage von Facebook
                jedoch auch in die USA und in andere Drittländer übertragen.
              </p>{" "}
              <p>
                So kann das Verhalten der Seitenbesucher nachverfolgt werden,
                nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
                Website des Anbieters weitergeleitet wurden. Dadurch können die
                Wirksamkeit der Facebook-Werbeanzeigen für statistische und
                Marktforschungszwecke ausgewertet werden und zukünftige
                Werbemaßnahmen optimiert werden.
              </p>{" "}
              <p>
                Die erhobenen Daten sind für uns als Betreiber dieser Website
                anonym, wir können keine Rückschlüsse auf die Identität der
                Nutzer ziehen. Die Daten werden aber von Facebook gespeichert
                und verarbeitet, sodass eine Verbindung zum jeweiligen
                Nutzerprofil möglich ist und Facebook die Daten für eigene
                Werbezwecke, entsprechend der{" "}
                <a
                  href="https://de-de.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook-Datenverwendungsrichtlinie
                </a>{" "}
                verwenden kann. Dadurch kann Facebook das Schalten von
                Werbeanzeigen auf Seiten von Facebook sowie außerhalb von
                Facebook ermöglichen. Diese Verwendung der Daten kann von uns
                als Seitenbetreiber nicht beeinflusst werden.
              </p>{" "}
              <p>
                Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an effektiven Werbemaßnahmen unter Einschluss der
                sozialen Medien. Sofern eine entsprechende Einwilligung
                abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
                Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage
                von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/legal/EU_data_transfer_addendum
                </a>{" "}
                und{" "}
                <a
                  href="https://de-de.facebook.com/help/566994660333381"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://de-de.facebook.com/help/566994660333381
                </a>
                .
              </p>{" "}
              <p>
                Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
                Daten auf unserer Website erfasst und an Facebook weitergeleitet
                werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für
                diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die
                gemeinsame Verantwortlichkeit beschränkt sich dabei
                ausschließlich auf die Erfassung der Daten und deren Weitergabe
                an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung
                durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die
                uns gemeinsam obliegenden Verpflichtungen wurden in einer
                Vereinbarung über gemeinsame Verarbeitung festgehalten. Den
                Wortlaut der Vereinbarung finden Sie unter:{" "}
                <a
                  href="https://www.facebook.com/legal/controller_addendum"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/legal/controller_addendum
                </a>
                . Laut dieser Vereinbarung sind wir für die Erteilung der
                Datenschutzinformationen beim Einsatz des Facebook-Tools und für
                die datenschutzrechtlich sichere Implementierung des Tools auf
                unserer Website verantwortlich. Für die Datensicherheit der
                Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
                (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook
                verarbeiteten Daten können Sie direkt bei Facebook geltend
                machen. Wenn Sie die Betroffenenrechte bei uns geltend machen,
                sind wir verpflichtet, diese an Facebook weiterzuleiten.
              </p>{" "}
              <p>
                In den Datenschutzhinweisen von Facebook finden Sie weitere
                Hinweise zum Schutz Ihrer Privatsphäre:{" "}
                <a
                  href="https://de-de.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://de-de.facebook.com/about/privacy/
                </a>
                .
              </p>{" "}
              <p>
                Sie können außerdem die Remarketing-Funktion „Custom Audiences“
                im Bereich Einstellungen für Werbeanzeigen unter{" "}
                <a
                  href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                </a>{" "}
                deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
              </p>{" "}
              <p>
                Wenn Sie kein Facebook Konto besitzen, können Sie
                nutzungsbasierte Werbung von Facebook auf der Website der
                European Interactive Digital Advertising Alliance deaktivieren:{" "}
                <a
                  href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.youronlinechoices.com/de/praferenzmanagement/
                </a>
                .
              </p>
              <br />
              <h2>6. Newsletter</h2>
              <h3>Newsletter­daten</h3>{" "}
              <p>
                Wenn Sie den auf der Website angebotenen Newsletter beziehen
                möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
                Informationen, welche uns die Überprüfung gestatten, dass Sie
                der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
                Empfang des Newsletters einverstanden sind. Weitere Daten werden
                nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten
                verwenden wir ausschließlich für den Versand der angeforderten
                Informationen und geben diese nicht an Dritte weiter.
              </p>{" "}
              <p>
                Die Verarbeitung der in das Newsletteranmeldeformular
                eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
                Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
                deren Nutzung zum Versand des Newsletters können Sie jederzeit
                widerrufen, etwa über den „Austragen“-Link im Newsletter. Die
                Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
                bleibt vom Widerruf unberührt.
              </p>{" "}
              <p>
                Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
                hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
                dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
                gespeichert und nach der Abbestellung des Newsletters oder nach
                Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir
                behalten uns vor, E-Mail-Adressen aus unserem
                Newsletterverteiler nach eigenem Ermessen im Rahmen unseres
                berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu
                löschen oder zu sperren.
              </p>{" "}
              <p>
                Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
                E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf.
                in einer Blacklist gespeichert, um künftige Mailings zu
                verhindern. Die Daten aus der Blacklist werden nur für diesen
                Zweck verwendet und nicht mit anderen Daten zusammengeführt.
                Dies dient sowohl Ihrem Interesse als auch unserem Interesse an
                der Einhaltung der gesetzlichen Vorgaben beim Versand von
                Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1
                lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich
                nicht befristet.{" "}
                <strong>
                  Sie können der Speicherung widersprechen, sofern Ihre
                  Interessen unser berechtigtes Interesse überwiegen.
                </strong>
              </p>
              <h2>7. Plugins und Tools</h2>
              <h3>YouTube</h3>{" "}
              <p>
                Diese Website bindet Videos der Website YouTube ein. Betreiber
                der Website ist die Google Ireland Limited („Google“), Gordon
                House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
                eingebunden ist, wird eine Verbindung zu den Servern von YouTube
                hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
                unserer Seiten Sie besucht haben.
              </p>{" "}
              <p>
                Des Weiteren kann YouTube verschiedene Cookies auf Ihrem
                Endgerät speichern oder vergleichbare Technologien zur
                Wiedererkennung verwenden (z.B. Device-Fingerprinting). Auf
                diese Weise kann YouTube Informationen über Besucher dieser
                Website erhalten. Diese Informationen werden u. a. verwendet, um
                Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
                verbessern und Betrugsversuchen vorzubeugen.
              </p>{" "}
              <p>
                Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen
                Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
                zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
                YouTube-Account ausloggen.
              </p>{" "}
              <p>
                Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
                Darstellung unserer Online-Angebote. Dies stellt ein
                berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
                Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                der Datenschutzerklärung von YouTube unter:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Google Web Fonts</h3>{" "}
              <p>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
                so genannte Web Fonts, die von Google bereitgestellt werden.
                Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web
                Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
                anzuzeigen.
              </p>{" "}
              <p>
                Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
                zu den Servern von Google aufnehmen. Hierdurch erlangt Google
                Kenntnis darüber, dass über Ihre IP-Adresse diese Website
                aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf
                Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                hat ein berechtigtes Interesse an der einheitlichen Darstellung
                des Schriftbildes auf seiner Website. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
                Standardschrift von Ihrem Computer genutzt.
              </p>{" "}
              <p>
                Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
                <a
                  href="https://developers.google.com/fonts/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://developers.google.com/fonts/faq
                </a>{" "}
                und in der Datenschutzerklärung von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Font Awesome</h3>{" "}
              <p>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
                und Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6
                Porter Road Apartment 3R, Cambridge, Massachusetts, USA.
              </p>{" "}
              <p>
                Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in
                ihren Browsercache, um Texte, Schriftarten und Symbole korrekt
                anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete
                Browser Verbindung zu den Servern von Font Awesome aufnehmen.
                Hierdurch erlangt Font Awesome Kenntnis darüber, dass über Ihre
                IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Font
                Awesome erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
                Wir haben ein berechtigtes Interesse an der einheitlichen
                Darstellung des Schriftbildes auf unserer Website. Sofern eine
                entsprechende Einwilligung abgefragt wurde (z. B. eine
                Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
                a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine
                Standardschrift von Ihrem Computer genutzt.
              </p>{" "}
              <p>
                Weitere Informationen zu Font Awesome finden Sie und in der
                Datenschutzerklärung von Font Awesome unter:{" "}
                <a
                  href="https://fontawesome.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://fontawesome.com/privacy
                </a>
                .
              </p>
              <h3>Google Maps</h3>{" "}
              <p>
                Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
                Google Ireland Limited („Google“), Gordon House, Barrow Street,
                Dublin 4, Irland.
              </p>{" "}
              <p>
                Zur Nutzung der Funktionen von Google Maps ist es notwendig,
                Ihre IP-Adresse zu speichern. Diese Informationen werden in der
                Regel an einen Server von Google in den USA übertragen und dort
                gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
                diese Datenübertragung. Wenn Google Maps aktiviert ist, kann
                Google zum Zwecke der einheitlichen Darstellung der Schriftarten
                Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr
                Browser die benötigten Web Fonts in ihren Browsercache, um Texte
                und Schriftarten korrekt anzuzeigen.
              </p>{" "}
              <p>
                Die Nutzung von Google Maps erfolgt im Interesse einer
                ansprechenden Darstellung unserer Online-Angebote und an einer
                leichten Auffindbarkeit der von uns auf der Website angegebenen
                Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
                Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Datenübertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gestützt. Details
                finden Sie hier:{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/
                </a>{" "}
                und{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
                </a>
                .
              </p>{" "}
              <p>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                Datenschutzerklärung von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Spotify</h3>{" "}
              <p>
                Auf dieser Website sind Funktionen des Musik-Dienstes Spotify
                eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61,
                113 56 Stockholm in Schweden. Die Spotify Plugins erkennen Sie
                an dem grünen Logo auf dieser Website. Eine Übersicht über die
                Spotify-Plugins finden Sie unter:{" "}
                <a
                  href="https://developer.spotify.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://developer.spotify.com
                </a>
                .
              </p>{" "}
              <p>
                Dadurch kann beim Besuch dieser Website über das Plugin eine
                direkte Verbindung zwischen Ihrem Browser und dem Spotify-Server
                hergestellt werden. Spotify erhält dadurch die Information, dass
                Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie
                den Spotify Button anklicken, während Sie in Ihrem
                Spotify-Account eingeloggt sind, können Sie die Inhalte dieser
                Website auf Ihrem Spotify Profil verlinken. Dadurch kann Spotify
                den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
              </p>{" "}
              <p>
                Wir weisen darauf hin, dass bei der Nutzung von Spotify Cookies
                von Google Analytics eingesetzt werden, sodass Ihre
                Nutzungsdaten bei der Nutzung von Spotify auch an Google
                weitergegeben werden können. Google Analytics ist ein Tool des
                Google-Konzerns zur Analyse des Nutzerverhaltens mit Sitz in den
                USA. Für diese Einbindung ist allein Spotify verantwortlich. Wir
                als Websitebetreiber haben auf diese Verarbeitung keinen
                Einfluss.
              </p>{" "}
              <p>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an der ansprechenden akustischen
                Ausgestaltung seiner Website. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Weitere Informationen hierzu finden Sie in der
                Datenschutzerklärung von Spotify:{" "}
                <a
                  href="https://www.spotify.com/de/legal/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.spotify.com/de/legal/privacy-policy/
                </a>
                .
              </p>{" "}
              <p>
                Wenn Sie nicht wünschen, dass Spotify den Besuch dieser Website
                Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte
                aus Ihrem Spotify-Benutzerkonto aus.
              </p>
              <h2>8. Eigene Dienste</h2>
              <h3>Umgang mit Bewerberdaten</h3>{" "}
              <p>
                Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z.
                B. per E-Mail, postalisch oder via Online-Bewerberformular). Im
                Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
                Ihrer im Rahmen des Bewerbungsprozesses erhobenen
                personenbezogenen Daten. Wir versichern, dass die Erhebung,
                Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit
                geltendem Datenschutzrecht und allen weiteren gesetzlichen
                Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
                werden.
              </p>{" "}
              <h4>Umfang und Zweck der Datenerhebung </h4>{" "}
              <p>
                Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir
                Ihre damit verbundenen personenbezogenen Daten (z. B. Kontakt-
                und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen
                von Bewerbungsgesprächen etc.), soweit dies zur Entscheidung
                über die Begründung eines Beschäftigungsverhältnisses
                erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG-neu nach
                deutschem Recht (Anbahnung eines Beschäftigungsverhältnisses),
                Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und –
                sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit.
                a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre
                personenbezogenen Daten werden innerhalb unseres Unternehmens
                ausschließlich an Personen weitergegeben, die an der Bearbeitung
                Ihrer Bewerbung beteiligt sind.
              </p>{" "}
              <p>
                Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
                eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6
                Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
                Beschäftigungsverhältnisses in unseren
                Datenverarbeitungssystemen gespeichert.
              </p>{" "}
              <h4>Aufbewahrungsdauer der Daten</h4>{" "}
              <p>
                Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
                Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen,
                behalten wir uns das Recht vor, die von Ihnen übermittelten
                Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs.
                1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des
                Bewerbungsverfahrens (Ablehnung oder Zurückziehung der
                Bewerbung) bei uns aufzubewahren. Anschließend werden die Daten
                gelöscht und die physischen Bewerbungsunterlagen vernichtet. Die
                Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines
                Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach
                Ablauf der 6-Monatsfrist erforderlich sein werden (z.B. aufgrund
                eines drohenden oder anhängigen Rechtsstreits), findet eine
                Löschung erst statt, wenn der Zweck für die weitergehende
                Aufbewahrung entfällt.
              </p>{" "}
              <p>
                Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie
                eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der
                Löschung entgegenstehen.
              </p>
            </div>
          </MDBRow>
        </MDBContainer>
      </div>
    </Layout>
  )
}

export default DaraPrivacy